.email-form {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 120px;;
  width: 300px;
}

.form-group {
  font-family: light;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Space between input fields */
  width: 100%; /* Match width of .email-form */
}

.form-group p {
  margin-bottom: 0px;;
}

.input-form {
  width: 100%; /* Match the width of .email-form */
  margin: 0; /* Remove extra margin for better alignment */
  background-color: #c7c7c72b;
  border-radius: 10px;
  padding: 9px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.input-form.message {
  height: 150px;
}

.error-placeholder {
  height: 8px; /* Reserve space for the error message */
  margin-top: 2px; /* Adds spacing between input and the placeholder */
  color: red;
  font-size: 0.85em;
  line-height: 1.5em;
  visibility: hidden; /* Initially hidden */
}

.error-placeholder.visible {
  visibility: visible; /* Show the error text when an error exists */
}
