.navigation-fixed {
    height: 80px;
}

.navbar-title {
    margin-top: 5px;
    font-size: 20px;
    font-family: "Light";
}

.nav-links.active-link {
    background-color: rgb(0, 0, 0); /* Light blue background */
    color: #ffffff; /* Blue text */
    font-weight: bold;
  }

  
.navbar-logo {
    animation: rotation 5s;
    width: 40px;
    height: 40px;
}

.navbar-logo:hover {
    transition: transform 1s ease-in-out;
    animation: rotation infinite 1s;
}

.navbar-logo-and-title {
    width: 100%;
    text-align: center;
    margin-left: 3%;
    color: black;
    margin-right: 60px;
}

.navbar-collapse {
    border-radius: 5px;

}

.navbar {
    font-size: 20px;
    height: 130px;
    z-index: 999;
    width: 100%;
    background: none;
    transition: max-height 1s ease;
    transition: background-color 500ms linear;

}

.navbar-toggler {
    margin-right: 30px;
}

.navbar.active {
    max-height: 75px;
    background-color: #f6f6f6f0;
    padding-top: 5px;
    transform-origin: top;
}

.navbar-logo.active {
    height: 30px;
    width: 30px;
}

.nav-links {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
    text-decoration: none;
  
    color: rgb(0, 0, 0);
    /*will change color of text within the element */
    border-radius: 5px;
  }
  
  .nav-links:hover {
    background-color: rgba(0, 0, 0, 0.938);
    /*will change background-color of element on hover */
    color: #ffffff;
    /*will change color of text within the element on hover */
  
  }


  