.progressive-image-container {
    position: relative;
    width: 100%;
    /* Match the width of the parent */
    height: 180px;
    /* Ensure consistent height */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;

  }