.banner-img-container {
    position: relative; /* Enables stacking child elements */
    width: 100vw; /* Full width of the viewport */
    height: 30vh; /* 50% of the viewport height */
    overflow: hidden; /* Ensures content does not overflow */
  }
  
  .banner-img {
    position: absolute; /* Places the background layer */
    top: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
    background-size: cover; /* Ensures the image always covers the full width and height */
    background-position: left center; /* Keeps the left side fixed */
    background-repeat: no-repeat; /* Prevents repetition of the image */
  
    /* Combined transparency masks */
    -webkit-mask-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(to right, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(to left, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0) 100%);  /* Vertical gradient */
    /* Vertical gradient */  -webkit-mask-size: 100% 100%; /* Ensures the mask covers the full image */
    -webkit-mask-composite: destination-in; /* Combines the masks */
    mask-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.5) 85%, rgba(0, 0, 0, 0) 100%), /* Vertical gradient */
    linear-gradient(to right, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(to left, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0) 100%);  /* Vertical gradient */
  
    mask-size: 100% 100%; /* Ensures the mask covers the full image */
    mask-composite: intersect; /* Combines the masks */
  }
  
  .banner-overlay-text {
    position: absolute; /* Positions the text relative to the parent container */
    top: 20%; /* Adjust as needed to place it closer to the top */
    right: 20%; /* Adjust as needed to place it closer to the right */
    z-index: 1; /* Ensures the text appears above the background */
    color: #04003b; /* Text color */
    font-family: "Light"; /* Font family */
  
    text-shadow: 0px 0px 10px #9e9e9e, /* Core glow */
                 0px 0px 15px #EAEAEA, /* Fainter glow */
                 0px 0px 20px #EAEAEA; /* Fully transparent at the outer edges */
    font-size: 40px;
    text-align: right; /* Aligns the text content to the right if multiline */
  }
  
  .banner-overlay-text .fade-in {
    opacity: 0; /* Start fully transparent */
    animation: fadeIn 3s ease forwards; /* Fades in over 2 seconds */
  }
  
  .banner-overlay-text .first {
    animation-delay: 3s; /* First text appears after 1 second */
  }
  
  .banner-overlay-text .second {
    animation-delay: 4s; /* Second text appears after 2 seconds */
  }
  
  
  @keyframes fadeIn {
    to {
      opacity: 1; /* Fade in to full opacity */
    }
  }
  
  
  .banner-overlay-text hr {
    border: 0; /* Removes default border styling */
    height: 3px; /* Sets thickness to 3px */
    background-color: black; /* Sets the color to black */
    width: 100%; /* Optional: Adjust width as needed */
    margin: 10px 0; /* Adds spacing around the line */
  }
  


  @media screen and (max-width: 1200px) {


    .banner-overlay-text {
        position: absolute;
        /* Positions the text relative to the parent container */
        top: 5%;
        /* Adjust as needed to place it close to the top */
        right: 10%;
        /* Adjust as needed to place it close to the right */
        z-index: 1;
        /* Ensures the text appears above the background */
        color: #04003b;
        /* Text color */
        font-family: "Light";
        /* Font family */
        text-shadow: 0px 0px 10px #9e9e9e,
          /* Core glow */
          0px 0px 15px #EAEAEA,
          /* Fainter glow */
          0px 0px 20px #EAEAEA;
        /* Fully transparent at the outer edges */
        font-size:25px;
        text-align: right;
        /* Aligns the text content to the right if multiline */
      }
  }