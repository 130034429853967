.footer {
    margin-top: auto;
    font-family: "Light";
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    bottom: 0;
    justify-content: center;
    background: linear-gradient(90deg,
            rgba(237, 237, 237, 1) 0%,
            rgba(237, 237, 237, 1) 100%);
    flex-wrap: wrap;

}


.footer-mail {
    text-decoration: none;
    color: black;
}

.footerc1 {
    flex-grow: 1;
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 5px 5px 5px 25px;

}

.footer-nav-hover:hover {
    color: #79abfc;
}

.footer-nav-hover {
    color: rgb(0, 0, 0);
}

.footer-main-container {
    display: flex;
}

.footer-dynamic-logo {
    display: flex;
    flex-flow: row wrap;
}



  