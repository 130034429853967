.generic-btn {
    margin-top: 0;
    background-color: #000000; /* Blue button for "Mehr Erfahren" */
    padding: 12px 12px 8px 12px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    width: auto;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 0.2s, background-color 0.3s;
    text-align: center;
  }
  
  .generic-btn:hover {
    transform: scale(1.05);
    background-color: #0056b3;
  }
  
  .generic-btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }
  
  .spacer-div-30px {
    height: 30px;
  }
  