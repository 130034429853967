.about-us-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default 4 columns */
    gap: 40px; /* Space between items */
    row-gap: 70px;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 30px;
    padding-bottom: 15px;
    margin-bottom: 60px;
}

.about-us-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensures content aligns to the top */
    align-items: stretch;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    padding: 4%; /* Add internal spacing */
    position: relative;
    text-align: left; /* Align text consistently */
    min-height: 400px; /* Set consistent height across items */
}

.about-us-item > * {
    margin-top: 0;
    margin-bottom: 0; /* Reset margins for consistent spacing */
}

.about-us-item.visible {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Back to original position */
}

.about-us-item .lottie-animation-wrapper,
.about-us-item img {
   /* Ensure consistent space for images */
    margin-bottom: auto; /* Push text down */
    display: block;
    flex-shrink: 0; /* Prevent collapsing */
}

.about-us-item img {
    margin-top: 10px; /* Ensure consistent spacing for images */
    display: block;
}




.about-us-item p {
    font-size: 17px;
    font-family: "Light";
    color: #000000;
    margin-top: 10px;
    text-align: justify; /* Justifies the text, spreading it evenly */
    text-justify: inter-word;
    flex-shrink: 0; /* Prevent paragraph from being squished */
    margin-bottom: 0; /* Ensure no extra spacing below */
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
    .about-us-grid {
        row-gap: 30px;
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 40px;
        padding-bottom: 15px;
        grid-template-columns: repeat(1, 1fr);
    }
}

